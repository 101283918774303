$defaultBackground: rgb(26, 26, 26);
$defaultTextColor: rgb(196, 196, 196);
$defaultFontFamily: "Inter", sans-serif;

* {
    cursor: url("../public/cursors/3dpixel/default.png"), default !important;
}

.pointer-c,
*:any-link {
    cursor: url("../public/cursors/3dpixel/pointer.png"), pointer !important;
}

@font-face {
    font-family: "Inter";
    src: url("./fonts/Inter/Inter-VariableFont_slnt,wght.ttf");
}

@font-face {
    font-family: "GGSansBold";
    src: url("./fonts/ggsans/bold.ttf")
}

@font-face {
    font-family: "GGSans";
    src: url("./fonts/ggsans/regular.ttf")
}

@font-face {
    font-family: "GGSansMedium";
    src: url("./fonts/ggsans/medium.ttf")
}

@font-face {
    font-family: "GGSansSemi";
    src: url("./fonts/ggsans/semi.ttf")
}

@keyframes DropShadowTop {
    from {
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0.4869130025056898) 0%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.4869130025056898) 0%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4869130025056898) 0%, rgba(0, 0, 0, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
    }

    to {
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0.9827113218334209) 0%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.9827113218334209) 0%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.9827113218334209) 0%, rgba(0, 0, 0, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
    }
}

html,
body {
    //core
    margin: 0;
    padding: 0;

    //background

    //text
    font-family: $defaultFontFamily;
}

*:has(.fa-edit) {
    color: #7c55ff
}

a {
    color: #5f2eff;
    font-weight: 500;
    text-decoration: none;
    text-shadow: rgba(190, 37, 245, 0.325) 0 0 5px;
}