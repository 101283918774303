#pageBackground {
    background-image: url("../../public/staticNoise.gif");
    opacity: 0.05;
    position: absolute;
    z-index: -999;
    height: 100%;
    width: 100%;
}

@keyframes hoverProfile {
    from {
        transform: translateY(0px)
    }

    to {
        transform: translateY(30px)
    }
}

@keyframes biFlagColors {
    0% {
        color: #D60270
    }

    50% {
        color: #9B4F96
    }

    100% {
        color: #0038A8
    }
}

@keyframes rainbowColor {
    0% {
        color: red
    }

    10% {
        color: orange
    }

    20% {
        color: yellow
    }

    30% {
        color: lime
    }

    40% {
        color: cyan
    }

    50% {
        color: blue
    }

    60% {
        color: indigo
    }

    70% {
        color: purple
    }

    80% {
        color: #ff00b6
    }

    90% {
        color: #ff0041
    }

    100% {
        color: #ec131a
    }
}

#hoverDownUp {
    animation-name: hoverProfile;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1.0);
    animation-direction: alternate;
    animation-delay: 1s;
}

.pFullName {
    animation-name: biFlagColors;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}